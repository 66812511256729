import { useContext, useEffect, useState } from "react";
import { MemberContext } from "../Contexts/MemberContext";
import { useNavigate } from "react-router-dom";
import "./MemberList.scss";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

const MemberList = () => {
  const navigate = useNavigate();
  const { currentUserInfo } = useContext(MemberContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showNonMember, setShowNonMember] = useState(false);
  const [membersList, setMembersList] = useState(false);
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  useEffect(() => {
    const membersCollection = collection(db, "members");

    // Firestoreの変更を監視
    const unsubscribe = onSnapshot(membersCollection, (snapshot) => {
      const updatedMembersList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMembersList(updatedMembersList);
    });

    // コンポーネントがアンマウントされたときにリスナーを解除
    return () => unsubscribe();
  }, []);
  const isAdminStrator = currentUserInfo?.administrator === true;

  //管理者認証者のみ閲覧するよう設定
  //遷移時はcurrentUserInfoから、再表示時はセッションから取得と思われる。
  //軽くループしている状態で、効率化の検討が必要
  useEffect(() => {
    const savedUserInfo = JSON.parse(sessionStorage.getItem("currentUserInfo"));

    // currentUserInfo が null または admin プロパティが undefined でないことを確認
    // const isCurrentUserAdmin = currentUserInfo?.admin === true;
    if (!isAdmin) {
      setIsLoading(true);
      alert("メンバー一覧は会員認証後に閲覧できます");
      navigate("/eventlist");
      setIsLoading(false); // ログインページに遷移
    } else {
      if (savedUserInfo) {
        console.log("セッション情報を取得しました");
      }
      // admin でない場合、リダイレクト
      else {
      }
    }
  }, [currentUserInfo, navigate]);

  const handleButtonClick = (id) => {
    if (
      (currentUserInfo && currentUserInfo.author.id === id) ||
      currentUserInfo?.administrator
    ) {
      navigate(`/membercreate`, { state: { authorId: id } });
    } else {
      alert("設定は本人と管理者だけが閲覧や編集できます。");
    }
  };

  // 削除ボタン
  const handleDeleteButtonClick = async (id) => {
    const member = membersList.find((member) => member.author.id === id);
    if (!member) {
      alert("該当する会員が見つかりませんでした。");
      return;
    }

    const confirmDelete = window.confirm(
      `本当に${member.accountname}を削除しますか？`
    );
    if (!confirmDelete) return;

    try {
      // Firestoreから削除
      await deleteDoc(doc(db, "members", member.id));

      // イベント参加情報を削除
      const eventMembersQuery = query(
        collection(db, "event_members"),
        where("memberId", "==", id)
      );
      const eventMembersSnapshot = await getDocs(eventMembersQuery);

      if (!eventMembersSnapshot.empty) {
        for (const docSnap of eventMembersSnapshot.docs) {
          await deleteDoc(docSnap.ref);
        }
      }

      // メンバーリストを更新
      const updatedMembersList = Object.values(membersList).filter(
        (m) => m.author.id !== id
      );
      setMembersList(updatedMembersList);
    } catch (error) {
      console.error("削除に失敗しました:", error);
    }
  };

  //メンバー一覧の表示から会員外登録した人の表示をしないようにします。
  const memberOnlyList = Object.values(membersList).filter(
    (member) => !member.author.id.includes("nonMember")
  );

  const nonMemberList = Object.values(membersList).filter((member) =>
    member.author.id.includes("nonMember")
  );

  // チェックボックスの変更ハンドラー
  const handleCheckboxChange = () => {
    setShowNonMember(!showNonMember); // チェックボックスの状態をトグル
  };

  //新しいメンバー詳細です
  const handleMemberDetail = (id) => {

    if (isAdmin) {
      navigate(`/memberdetail`, { state: { authorId: id } });
    } else {
      alert("公開プロフィールは会員だけが閲覧できます。");
      navigate(`/eventlist`);
    }
  };
  //新しいメンバー詳細です
  const handleMembersencitive = (id) => {

    if (
      (currentUserInfo && currentUserInfo.author.id === id) ||
      currentUserInfo?.administrator
    ) {
      navigate(`/membercreate`, { state: { authorId: id } });
    } else {
      alert("詳細は本人と管理者だけが閲覧や編集できます。");
    }
  };

  return (
    <div className="memberlist-container">
      {isLoading && (
        <div className="overlay">
          <div className="loading-message">処理中...</div>
        </div>
      )}
      <div className={`memberlist-field ${isAdminStrator ? "" : "member"}`}>
        <table className={`table_layout ${isAdminStrator ? "" : "member"}`}>
          <thead>
            <tr>
              <th className="hide-on-narrow">
                <span className="member_username hide-on-narrow ">お名前</span>
              </th>

              <th>
                <span className="member_accountname ">アカウント名</span>
              </th>
              {isAdminStrator ? (
                <>
                  <th className="hide-on-narrow">
                    <span className="member_email hide-on-narrow ">
                      メールアドレス
                    </span>
                  </th>{" "}
                  <th className="hide-on-narrow">
                    <span className="member_phone hide-on-narrow">
                      携帯番号
                    </span>
                  </th>{" "}
                </>
              ) : (
                ""
              )}

              <th>
                <span className="member_profile ">公開プロフィール</span>
              </th>
              {isAdminStrator ? (
                <>
                  <th className="hide-on-narrow">
                    <span className="member_admin hide-on-narrow">
                      参加承認
                    </span>
                  </th>

                  <th className="hide-on-narrow">
                    <span className="member_administrator ">管理権限</span>
                  </th>
                </>
              ) : (
                ""
              )}
              <th>
                <span
                  className="member_detail"
                  // style={{
                  //   width: "30px",
                  // }}
                >
                  設定
                </span>
              </th>
              {isAdminStrator ? (
                <>
                  <th>
                    <span className="member_delete ">削除</span>
                  </th>
                </>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {memberOnlyList.map((member, index) => (
              <tr key={member.id}>
                <td className="member_name2 hide-on-narrow">
                  {member.username}
                </td>{" "}
                <td className="member_accountname2">{member.accountname}</td>
                {isAdminStrator ? (
                  <>
                    <td className="member_email2 hide-on-narrow">
                      {member.email}
                    </td>
                    <td className="member_tel_num2 hide-on-narrow">
                      {member.tel_num}
                    </td>
                  </>
                ) : (
                  ""
                )}
                <td className="member_profile2">
                  {" "}
                  <button
                    className="memberlist-detail-profile-button"
                    onClick={() => handleMemberDetail(member.author.id)}
                  >
                    会員情報
                  </button>{" "}
                </td>
                {isAdminStrator ? (
                  <>
                    <td className="member_admin2 hide-on-narrow">
                      {member.admin ? "■" : "□"}
                    </td>

                    <td className="member_adminstrator2 hide-on-narrow">
                      {member.administrator ? "■" : "□"}
                    </td>
                  </>
                ) : (
                  ""
                )}
                <td className="member_detail2">
                  <button
                    className="memberlist-detail-button"
                    onClick={() => handleMembersencitive(member.author.id)}
                  >
                    設定
                  </button>
                </td>
                {isAdminStrator ? (
                  <>
                    <td className="member_delete2 ">
                      <button
                        className="memberlist-delete-button"
                        onClick={() =>
                          handleDeleteButtonClick(member.author.id)
                        }
                      >
                        削除
                      </button>
                    </td>{" "}
                  </>
                ) : (
                  ""
                )}{" "}
              </tr>
            ))}
            {isAdminStrator ? (
              <>
                <tr>
                  <td
                    colSpan={9}
                    className="nonMemberShow"
                    style={{ textAlign: "end" }}
                  >
                    <div className="nonMemberShow-button">
                      <label>会員外を表示</label>
                      <input
                        type="checkbox"
                        checked={showNonMember}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </td>
                </tr>{" "}
              </>
            ) : (
              ""
            )}

            {showNonMember &&
              nonMemberList.map((member, index) => (
                <tr key={member.id}>
                  <td className="member_name2 hide-on-narrow">
                    {member.username}
                  </td>{" "}
                  <td className="member_accountname2">{member.accountname}</td>
                  {isAdminStrator ? (
                    <>
                      <td className="member_email2 hide-on-narrow">
                        {member.email}
                      </td>
                      <td className="member_tel_num2 hide-on-narrow">
                        {member.tel_num}
                      </td>
                    </>
                  ) : (
                    ""
                  )}
                  <td className="member_profile2">
                    {" "}
                    <button
                      className="memberlist-detail-profile-button"
                      onClick={() => handleMemberDetail(member.author.id)}
                    >
                      会員情報
                    </button>{" "}
                  </td>
                  {isAdminStrator ? (
                    <>
                      <td className="member_admin2 hide-on-narrow">
                        {member.admin ? "■" : "□"}
                      </td>

                      <td className="member_adminstrator2 hide-on-narrow">
                        {member.administrator ? "■" : "□"}
                      </td>
                    </>
                  ) : (
                    ""
                  )}
                  <td className="member_detail2">
                    <button
                      className="memberlist-detail-button"
                      onClick={() => handleMembersencitive(member.author.id)}
                    >
                      設定
                    </button>
                  </td>
                  {isAdminStrator ? (
                    <>
                      <td className="member_delete2 ">
                        <button
                          className="memberlist-delete-button"
                          onClick={() =>
                            handleDeleteButtonClick(member.author.id)
                          }
                        >
                          削除
                        </button>
                      </td>{" "}
                    </>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberList;
