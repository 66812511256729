import React, { useState, useEffect, useContext, useRef } from "react";
import { EventMembersContext } from "../Contexts/EventMembersContext";
import { MemberContext } from "../Contexts/MemberContext";
import { auth, db } from "../firebase";
import { EmailSenderBase } from "../utils/EmailSenderBase";
import "./PaticipantEmaiSender.scss";
import { useParams } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";

const PaticipantEmaiSender = () => {
  const { eventMembersList } = useContext(EventMembersContext); // 全イベント参加者データ
  const { currentUserInfo, membersList } = useContext(MemberContext); // 現在のユーザー情報
  const [showModal, setShowModal] = useState(false);
  const [subject, setSubject] = useState(""); // 初期値を空文字に変更
  const [text, setText] = useState(""); // 初期値を空文字に変更
  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const { id } = useParams();
  const handleMouseEnter = (index) => {
    if (inputRefs[index].current) {
      inputRefs[index].current.focus();
    }
  };

  // Enterキーで次の入力欄にフォーカスを移動
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      if (inputRefs[index + 1]?.current) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  // const id = "spl9w70wit7OyPTbvaZd";

  //参加予定者のemailを取得
  const paticipantEmail = () => {
    const apllyEventMemberList = eventMembersList.filter(
      (event) => event.eventId === id
    );
    return apllyEventMemberList;
  };
  const memberIds = paticipantEmail().map((particiant) => particiant.memberId);
  const matchedMembers = membersList.filter((member) =>
    memberIds.includes(member.author.id)
  );
  const toEmail = matchedMembers.map((member) => member.email);
  // const toEmail = id;
  console.log(toEmail);
  const handleEmailSend = async (e) => {
    e.preventDefault(); // フォームのデフォルト送信を防ぐ
    if (!subject || !text) {
      alert("全てのフィールドを入力してください。");
      return;
    }
    try {
      await EmailSenderBase([toEmail], subject, text);
      alert("メール送信が成功しました。");
      // メール送信成功後にFirestoreに情報を追加
      await addDoc(collection(db, "emailRecords"), {
        subject: subject,
        content: text,
        recipients: toEmail,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error("メール送信エラー:", error);
      alert("メール送信に失敗しました。");
    }
  };

  // ボタンがクリックされた時にsubjectとtextを設定する
  const handleButtonClick = (type) => {
    if (type === "rain") {
      setSubject("雨天中止のお知らせ");
      setText("本日は雨天中止ですので、よろしくお願いいたします。");
    } else if (type === "today") {
      setSubject("本日は開催いたします。");
      setText(
        "本日ですが天気予報では大丈夫そうなので、開催ということでよろしくお願いいたします。参加をお待ちしています。"
      );
    } else if (type === "ayashi") {
      setSubject("本日は開催いたします。");
      setText(
        "本日ですが天気予報では大丈夫そうなので、開催ということでよろしくお願いいたします。天気予報が外れた場合はお許しください。"
      );
    } else if (type === "flying") {
      setSubject("フライング開催");
      setText(
        "明日ですが前の時間コートが空いていますので、１５分程、フライング開催でよろしくお願いいたします。参加をお待ちしています。"
      );
    }
  };

  return (
    <>
      <div className="paticipant_emeil_sender_container">
        <div className="paticipant_emeil_sender_content">
          <div className="butto_waku">
            {/* ボタンをクリックすることでsubjectとtextを変更 */}
            <button onClick={() => handleButtonClick("rain")}>雨天中止</button>
            <button onClick={() => handleButtonClick("today")}>
              本日ですが
            </button>
            <button onClick={() => handleButtonClick("ayashi")}>
              怪しい天気
            </button>
          </div>
          <div className="butto_waku">
            <button onClick={() => handleButtonClick("flying")}>
              フライング
            </button>
          </div>
        </div>
        <div className="paticipant_emeil_sender_content">
          <form
            className="paticipant_emeil_sender_form"
            onSubmit={handleEmailSend}
          >
            <div className="paticipant_emeil_sender_field">
              <label
                className="paticipant_emeil_sender_label"
                onMouseEnter={() => handleMouseEnter(1)}
              >
                件 名 :
              </label>
              <input
                className="paticipant_emeil_sender_input"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="例: 本日の開催について"
                required
                onMouseEnter={() => handleMouseEnter(1)}
                onKeyDown={(e) => handleKeyDown(e, 1)} // Enterキーが押された時の処理
              />
            </div>
            <div className="paticipant_emeil_sender_field">
              <label
                className="paticipant_emeil_sender_label"
                onMouseEnter={() => handleMouseEnter(2)}
              >
                本 文 :
              </label>
              <textarea
                ref={inputRefs[2]}
                className="paticipant_emeil_sender_textarea"
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
                onMouseEnter={() => handleMouseEnter(2)}
              />
            </div>
            <button className="paticipant_emeil_sender_button" type="submit">
              送 信
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaticipantEmaiSender;
