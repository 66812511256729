import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Firestoreをインポート
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-f0_o4fNIcnupupH16VnItlauMKvqHS0",
  authDomain: "raising2004-160b9.firebaseapp.com",
  databaseURL:
    "https://raising2004-160b9-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "raising2004-160b9",
  storageBucket: "raising2004-160b9.firebasestorage.app",
  messagingSenderId: "1059702426042",
  appId: "1:1059702426042:web:7739e8f1a9343e171df583",
  measurementId: "G-435KSNG1S2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
// 各サービスを初期化

const functions = getFunctions(app);
const db = getFirestore(app); // Firestoreの初期化
export const storage = getStorage(app);

if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001); // localhost:5001を使用
}
export default app;
export { analytics, app, auth, db, functions };
