import React, { useContext, useEffect, useRef, useState } from "react";
import { db, storage } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "./CreatePost.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberContext } from "../Contexts/MemberContext";

const CreatePost = () => {
  const [section, setSection] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [preContent, setPreContent] = useState("");
  const textareaRef = useRef(null);
  const location = useLocation();
  const {
    createstate,
    eventTitle,
    eventDate,
    eventDateWeek,
    eventParticipantMember,
    postId,
  } = location.state || {};
  const [image, setImage] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUserInfo } = useContext(MemberContext);
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const postaccountname = currentUserInfo?.accountname;
  const postaccountId = currentUserInfo?.id;
  const [selectedOptions, setSelectedOptions] = useState([]);

  //参加者配列オブジェクトから、参加者名を抽出した配列を作成

  useEffect(() => {
    if (!isAdmin) {
      alert("投稿は会員だけができます。");
      navigate("/eventlist");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "20px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content]);

  useEffect(() => {
    if (eventTitle) {
      setTitle(`${eventDate}(${eventDateWeek}) ${eventTitle}`);
      setSelectedOptions(`event_report"`);
    }
  }, [eventDate, eventTitle]);

  const options = [
    { jp: "イベント報告", en: "event_report" },
    { jp: "会員交流", en: "member_exchange" },
    { jp: "お薦め情報", en: "recommendations" },
    { jp: "その他", en: "others" },
  ];

  const handleCheckboxChange = (event) => {
    setSelectedOptions([event.target.value]); // 1つだけ選択可能にする
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      resizeImage(e.target.files[0], 800, 800, 0.7);
    }
  };

  console.log(section.length);

  const resizeImage = (file, size, quality) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const minSize = Math.min(img.width, img.height);
        const offsetX = (img.width - minSize) / 2;
        const offsetY = (img.height - minSize) / 2;
        canvas.width = size;
        canvas.height = size;
        ctx.drawImage(
          img,
          offsetX,
          offsetY,
          minSize,
          minSize, // 元画像のクロップ領域
          0,
          0,
          size,
          size // キャンバスにリサイズ描画
        );

        // JPEG圧縮（0.7〜0.8の品質が推奨）
        canvas.toBlob(
          (blob) => {
            if (blob.size > 100 * 1024) {
              console.warn("圧縮後も100KB以下にならなかった");
            }
            setImage(blob);
          },
          "image/jpeg",
          quality
        );
      };
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedOptions.length === 0) {
      alert("分類を選んでください");
      return;
    }
    setLoading(true);

    let imageUrl = "";
    if (image) {
      const storageRef = ref(storage, `blog-images/${Date.now()}.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error(error);
            reject(error);
          },
          async () => {
            imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
            resolve();
          }
        );
      });
    }

    // const content = content;
    const section = options.reduce((acc, option) => {
      acc[option.en] = selectedOptions.includes(option.en);
      return acc;
    }, {});

    const honsection = Object.entries(section);
    const trueKey =
      honsection.find((honese) => honese[1] === true)?.[0] || null;

    console.log(trueKey); // ['other']
    // Firestoreに投稿を保存（YouTube URLも含める）

    await addDoc(collection(db, "posts"), {
      section,
      title,
      content: content,
      participant: eventParticipantMember ?? "",
      imageUrl,
      postaccountname,
      postaccountId,
      youtubeUrl,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    navigate(`/postlist`, { state: { createsection: trueKey } });
  };

  return (
    <>
      <div className="createpost_container">
        <div className="createpost_content">
          <h2>
            <span className="createpost_content_title">新しい記事を投稿</span>
            <div
              className="createpost-close-button"
              onClick={() => {
                navigate("/postlist");
              }}
            >
              ｘ
            </div>
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="createpost_field">
              {" "}
              <h3>分 類</h3>{" "}
              <div className="createpost_field_inner">
                <div className="createpost_field_section">
                  {options.map((option) => (
                    <label key={option.en} className="checkbox-label">
                      {option.jp}
                      <input
                        className="createpost_field_section_select"
                        type="checkbox"
                        value={option.en}
                        checked={selectedOptions.includes(option.en)}
                        onChange={handleCheckboxChange}
                      />
                    </label>
                  ))}
                </div>
              </div>{" "}
              <h3>タイトル</h3>{" "}
              <div className="createpost_field_inner">
                <input
                  type="text"
                  placeholder="タイトル"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <h3>内 容</h3>
              <div className="createpost_field_inner2">
                {/* <textarea
                  ref={textareaRef}
                  placeholder="内容"
                  value={`${content}
                  ${
                    eventParticipantMember
                      ? `\n参加者(${eventParticipantMember})`
                      : ""
                  }`}
                  onChange={(e) => setContent(e.target.value)}
                  style={{
                    minHeight: "80px",
                    overflow: "hidden",
                    resize: "none",
                  }}
                  required
                />{" "} */}
                <textarea
                  ref={textareaRef}
                  placeholder="内容"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  style={{
                    minHeight: "80px",
                    overflow: "hidden",
                    resize: "none",
                  }}
                  required
                />
              </div>
              {/* 参加者情報は別の場所に表示 */}
              {eventParticipantMember && (
                <>
                  {" "}
                  <h3>参加者</h3>
                  <div className="createpost_field_inner">
                    <input
                      type="text"
                      value={eventParticipantMember}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </div>{" "}
                  : ""
                </>
              )}
              <h3>
                アップする写真
                <span style={{ fontSize: "1.2rem" }}>
                  　(真四角になります)　
                </span>
              </h3>
              <div className="createpost_field_inner">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />{" "}
              </div>
              <h3>紹介するyoutube</h3>
              <div className="createpost_field_inner">
                <input
                  type="url"
                  placeholder="YouTube URL"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                />
              </div>
              <button
                type="submit"
                disabled={loading}
                className="createpost_submit_button"
              >
                {loading ? "投稿中..." : "投稿"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div></div>
    </>
  );
};

export default CreatePost;
