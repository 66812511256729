import { useEffect, useRef, useState } from "react";

export const PostEditForm = ({
  postId,
  existingTitle,
  existingSection,
  existingContent,
  existingParticipant,
  existingYoutubeUrl,
  existingNewImage,
  handleEditPost,
  setEditingPosts,
}) => {
  const [newTitle, setNewTitle] = useState(existingTitle);
  const [newSection, setNewSection] = useState(existingSection);
  const [newContent, setNewContent] = useState(existingContent);
  const [newParticipant, setNewParticipant] = useState(existingParticipant);
  const [isEditing, setIsEditing] = useState(false);
  const [newYoutubeUrl, setNewYoutubeUrl] = useState(existingYoutubeUrl);
  const [newImage, setNewImage] = useState(existingNewImage);
  const textareaRef = useRef(null);
  const [section, setSection] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  console.log(newSection);
  // const sectionArray = newSection.split(", ");

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "20px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newContent]);

  const options = [
    { jp: "イベント報告", en: "event_report" },
    { jp: "会員交流", en: "member_exchange" },
    { jp: "お薦め情報", en: "recommendations" },
    { jp: "その他", en: "other" },
  ];

  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    setNewSection({ [value]: true }); // 選択したものだけ `true` にする（1つだけ選択可能）
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      resizeImage(e.target.files[0], 800, 800, 0.7); // 画像のリサイズ（800px x 800px、品質0.7）
    }
  };

  const resizeImage = (file, maxWidth, maxHeight, quality) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // アスペクト比を維持しつつサイズ変更
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // JPEG圧縮（0.7〜0.8の品質が推奨）
        canvas.toBlob(
          (blob) => {
            if (blob.size > 100 * 1024) {
              console.warn("圧縮後も100KB以下にならなかった");
            }
            setNewImage(blob);
          },
          "image/jpeg",
          quality
        );
      };
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleEditPost(
      postId,
      newTitle,
      newSection,
      newContent,
      newParticipant,
      newImage,
      newYoutubeUrl
    );
    setIsEditing(false);
  };

  return isEditing ? (
    <>
      <form onSubmit={handleSubmit}>
        <div className="postlist_field_content_edit">
          <h4> 分 類</h4>
          <div className="postlist_field_content_edit_section">
            {options.map(({ en, jp }) => (
              <label key={en} className="checkbox-label">
                {jp}{" "}
                <input
                  className="postlist_field_section_select"
                  type="checkbox"
                  value={en}
                  checked={!!newSection[en]}
                  onChange={handleCheckboxChange}
                />
              </label>
            ))}
          </div>
          <h4> タイトル</h4>
          <input
            className="postlist_field_content_edit_title"
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            required
          />
          <h4>内 容</h4>
          <textarea
            ref={textareaRef}
            className="postlist_field_content_edit_content"
            value={newContent}
            onChange={(e) => setNewContent(e.target.value)}
            required
          />
          {newParticipant ? (
            <>
              <h4>参加者</h4>
              <input
                className="postlist_field_content_edit_content_small"
                value={newParticipant}
                onChange={(e) => setNewParticipant(e.target.value)}
              ></input>
            </>
          ) : (
            ""
          )}
          <input
            className="postlist_field_content_edit_image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <input
            className="postlist_field_content_edit_youtube"
            type="url"
            placeholder="YouTube URL"
            value={newYoutubeUrl}
            onChange={(e) => setNewYoutubeUrl(e.target.value)}
          />
          <div className="postlist_field_content_edit_buttons">
            <button
              type="submit"
              className="postlist_field_content_edit_submit_button"
            >
              更新
            </button>
            <button
              type="button"
              onClick={() => setEditingPosts(false)}
              className="postlist_field_content_edit_cancel_button"
            >
              キャンセル
            </button>
          </div>
        </div>
      </form>
    </>
  ) : (
    <button
      onClick={() => setIsEditing(true)}
      className="postlist_field_content_edit_button"
    >
      編集
    </button>
  );
};
