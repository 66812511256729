import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import { EventContext } from "Contexts/EventContext";
import "./EventInfo.scss";

const EventInfo = () => {
  const navigate = useNavigate();
  const { eventId, eventState } = useParams();
  const { eventList } = useContext(EventContext);
  const [isEditing, setIsEditing] = useState(false);
  const [event, setEvent] = useState({
    title: "",
    site: "",
    site_region: "",
    starttime_date: "00",
    starttime_hour: "00",
    starttime_minute: "00",
    duration_hour: "03",
    duration_minute: "00",
    endtime: "",
    court_surface: "",
    court_count: "1",
    court_num: "",
    capacity: "6",
    map: "",
    detail: "",
    rank: "",
    created_at: "",
    updated_at: "",
    deadline_hours_before: "",
  });

  //イベント一覧からeventIdが送られてきたとき、そのデータをeventデータにセットする。
  useEffect(() => {
    if (eventId) {
      const eventDoc = eventList.find((event) => event.id === eventId);
      if (eventDoc) {
        setEvent({
          ...eventDoc,
        });
        setIsEditing(true);
      } else {
        console.log("イベントが見つかりませんでした");
      }
    }
  }, [eventId, eventList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({
      ...event,
      [name]: value,
    });
  };

  const validateEventData = (event) => {
    const {
      starttime_date,
      starttime_hour,
      starttime_minute,
      deadline_hours_before,
    } = event;

    const starttime = `${starttime_date}T${starttime_hour}:${starttime_minute}:00+09:00`; // 日本時間を指定
    console.log("スタート時か" + starttime);
    const startTimeDate = new Date(starttime);
    if (isNaN(startTimeDate.getTime())) {
      alert("開始時間のフォーマットが無効です。");
      return false;
    }

    let deadlineDate = new Date(startTimeDate);

    if (deadline_hours_before) {
      deadlineDate.setHours(
        startTimeDate.getHours() - parseInt(deadline_hours_before)
      );
    }

    const deadlinedate = new Date(deadlineDate.getTime());
    const threeHoursBefore = new Date(startTimeDate);
    threeHoursBefore.setHours(startTimeDate.getHours() - 3);
    if (deadlinedate > threeHoursBefore) {
      alert("締切時間は開催時間の3時間前までに設定してください。");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEventData(event)) return;
    const {
      starttime_date,
      starttime_hour,
      starttime_minute,
      duration_hour,
      duration_minute,
      deadline_hours_before,
    } = event;

    const starttime = `${starttime_date}T${starttime_hour}:${starttime_minute}:00+09:00`; // 日本時間を指定
    const duration = `${duration_hour}:${duration_minute}`;

    const startTimeDate = new Date(starttime);
    let deadlineDate = new Date(startTimeDate);

    if (deadline_hours_before) {
      deadlineDate.setHours(
        startTimeDate.getHours() - parseInt(deadline_hours_before)
      );
    }
    const deadlinedate = new Date(deadlineDate.getTime());
    if (eventState === undefined) {
      try {
        const eventWithTimestampAndAuthor = {
          ...event,
          starttime,
          duration,
          deadlinedate: deadlinedate.toISOString(),
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          author: {
            username: auth.currentUser
              ? auth.currentUser.displayName
              : "anonymous",
            id: auth.currentUser ? auth.currentUser.uid : "unknown",
          },
        };
        await addDoc(collection(db, "events"), eventWithTimestampAndAuthor);
        navigate(`/eventselect`);
      } catch (error) {
        console.error("イベント情報の送信中にエラーが発生しました:", error);
      }
    } else if (eventState === "eventreuse") {
      try {
        const { id, ...restOfEvent } = event;
        const eventWithTimestampAndAuthor = {
          ...restOfEvent,
          starttime,
          duration,
          deadlinedate: deadlinedate.toISOString(),
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          author: {
            username: auth.currentUser
              ? auth.currentUser.displayName
              : "anonymous",
            id: auth.currentUser ? auth.currentUser.uid : "unknown",
          },
        };
        await addDoc(collection(db, "events"), eventWithTimestampAndAuthor);
        navigate(`/eventselect`);
      } catch (error) {
        console.error("イベント情報の送信中にエラーが発生しました:", error);
      }
    } else {
      try {
        const updatedData = {
          ...event,
          starttime,
          duration,
          deadlinedate: deadlinedate.toISOString(),
          updated_at: new Date().toISOString(),
        };

        // データをログに出力して確認
        console.log("Updating Firestore document with data:", updatedData);

        await updateDoc(doc(db, "events", eventId), updatedData);
        navigate("/eventlist");
      } catch (error) {
        console.error("イベントの更新中にエラーが発生しました:", error);
      }
    }
  };

  return (
    <div className="eventcreate-container">
      <div className="eventcreate-content">
        <h1>イベント情報入力フォーム</h1>
        <button
          className="eventcreate-close-button"
          onClick={() => {
            navigate("/eventselect");
          }}
        >
          ｘ
        </button>
        <form onSubmit={handleSubmit}>
          <div className="formRow">
            <div className="label1">タイトル</div>
            <input
              className="item1"
              type="text"
              name="title"
              value={event.title}
              onChange={handleChange}
              required
            />

            <div className="label2">開催場所</div>
            <input
              className="item2"
              type="text"
              name="site"
              value={event.site}
              onChange={handleChange}
              required
            />

            <div className="label3">地図</div>
            <input
              className="item3"
              type="text"
              name="map"
              value={event.map}
              onChange={handleChange}
              // required
            />

            <div className="label4">地域</div>
            <input
              className="item4"
              type="text"
              name="site_region"
              value={event.site_region}
              onChange={handleChange}
              required
            />

            <div className="label5">開始日</div>
            <input
              className="item5-2"
              type="date"
              name="starttime_date"
              value={event.starttime_date}
              onChange={handleChange}
              required
            />

            <div className="label6">開始時間</div>
            <select
              className="item6-1"
              name="starttime_hour"
              value={event.starttime_hour}
              onChange={handleChange}
              required
            >
              {[...Array(24).keys()].map((hour) => (
                <option key={hour} value={hour < 10 ? `0${hour}` : hour}>
                  {hour < 10 ? `0${hour}` : hour}
                </option>
              ))}
            </select>
            <p className="item6-3">時</p>
            <select
              className="item6-2"
              name="starttime_minute"
              value={event.starttime_minute}
              onChange={handleChange}
              required
            >
              <option value="00">00</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
            <p className="item6-4">分</p>

            <div className="label7">開催時間</div>
            <select
              className="item7-1"
              name="duration_hour"
              value={event.duration_hour}
              onChange={handleChange}
              required
            >
              {[...Array(14).keys()].map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
            <p className="item7-3">時間</p>

            <select
              className="item7-2"
              name="duration_minute"
              value={event.duration_minute}
              onChange={handleChange}
              required
            >
              <option value="00">00</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>

            <p className="item7-4">分</p>

            <div className="label8">締切</div>
            <select
              className="item8-1"
              name="deadline_hours_before"
              value={event.deadline_hours_before}
              onChange={handleChange}
              required
            >
              {[...Array(100).keys()].map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
            <p className="item8-2">時間前</p>

            <div className="label9">種類</div>
            <select
              className="item9"
              name="court_surface"
              value={event.court_surface}
              onChange={handleChange}
              required
            >
              <option value="オムニ">オムニ</option>
              <option value="ハードコート">ハードコート</option>
              <option value="土">土</option>
              <option value="クレイ">クレイ</option>
              <option value="カーペット">カーペット</option>
              <option value="板張り">板張り</option>
            </select>

            <div className="label10">面数</div>
            <select
              className="item10"
              name="court_count"
              value={event.court_count}
              onChange={handleChange}
              required
            >
              {[...Array(10).keys()].map((count) => (
                <option key={count} value={count}>
                  {count}
                </option>
              ))}
            </select>

            <div className="label11">面番号</div>
            <input
              className="item11"
              type="text"
              name="court_num"
              value={event.court_num}
              onChange={handleChange}
            />

            <div className="label12">定員</div>
            <select
              className="item12"
              name="capacity"
              value={event.capacity}
              onChange={handleChange}
              required
            >
              {[...Array(50).keys()].map((capacity) => (
                <option key={capacity} value={capacity}>
                  {capacity}
                </option>
              ))}
            </select>

            <div className="label14">備考</div>
            <textarea
              className="item14"
              name="detail"
              value={event.detail}
              onChange={handleChange}
            />
            <div className="label15"></div>
            <div className="item15">
              {" "}
              {eventState === "edit" ? (
                <button className="eventcreate-button" type="submit">
                  編集する
                </button>
              ) : (
                <button className="eventcreate-button" type="submit">
                  日程を登録する
                </button>
              )}{" "}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventInfo;
