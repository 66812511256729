import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Step2 = () => {
  const location = useLocation(); // Step1 から渡された state を取得
  const navigate = useNavigate();
  const [input2, setInput2] = useState("");

  useEffect(() => {
    if (location.state?.input1) {
      console.log("Received input1 from Step1:", location.state.input1);
    }
  }, [location]);

  const handleChange = (e) => setInput2(e.target.value);

  const handleNext = () => {
    navigate("/step3", {
      state: { ...location.state, input2 }, // input1 を引き継ぎ、input2 を追加
    });
  };

  return (
    <div>
      <h2>Step 2</h2>
      <input
        type="text"
        value={input2}
        onChange={handleChange}
        placeholder="Enter Input 2"
      />
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default Step2;
