// src/utils/fetchEventData.js
import { parseISO } from "date-fns";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase"; // Firebaseの設定がされているファイルからdbをインポート
import { convertDurationToMinutes } from "./convertDurationToMinutes";

export const subscribeToEventData = (callback) => {
  try {
    const oneWeekAgo = new Date();
    //何日前かを指定今回は当日
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 30);
    const eventCollection = collection(db, "events");
    // クエリを作成：starttimeが上記の日付以降のイベントを取得
    const eventQuery = query(
      eventCollection,
      where("starttime", ">=", oneWeekAgo.toISOString()) // created_atが一週間前以降
    );

    const unsubscribe = onSnapshot(eventQuery, (snapshot) => {
      const eventList = snapshot.docs.map((doc) => {
        const eventData = doc.data();
        const starttime = parseISO(eventData.starttime); // 必要に応じて日付形式を変換
        const durationInMinutes = convertDurationToMinutes(eventData.duration); // 必要に応じて変換
        return {
          id: doc.id,
          ...eventData,
          starttime: starttime,
          duration: durationInMinutes,
        };
      });
      callback(eventList);
    });

    return unsubscribe; // クリーンアップ用
  } catch (error) {
    console.error("Error subscribing to event data:", error);
    return () => {}; // 何も行わないクリーンアップ関数を返す
  }
};
