import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Step1 = ({ location, history }) => {
  const [input1, setInput1] = useState("");
  const handleChange = (e) => setInput1(e.target.value);
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/step2", {
      state: { input1 },
    });
  };

  return (
    <div>
      <h2>Step 1</h2>
      <input
        type="text"
        value={input1}
        onChange={handleChange}
        placeholder="Enter Input 1"
      />
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default Step1;
