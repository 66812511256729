export const ParticipantList = ({
  eventId,
  eventMembers,
  members = [],
  navigate,
  administrator,
  currentUser,
}) => {
  // イベントメンバーをフィルタリング
  const filteredMembers = eventMembers.filter(
    (member) => member.eventId === eventId
  );

  // メンバーIDに基づいてメンバーのアカウント名を取得
  const getAccountName = (memberId) => {
    // メンバーが undefined でないことを確認
    if (!members) {
      console.error("Members data is not available.");
      return "不明";
    }
    const member = members.find((m) => m.author.id === memberId);
    return member ? member.accountname : "不明";
  };

  // ボタンのリストを作成
  const participantButtons = filteredMembers.map((participanteventmember) => {
    // const isCurrentUser = participanteventmember.memberId === currentUser.uid;
    return (
      <button
        key={participanteventmember.memberId}
        onClick={() => {
          if (administrator) {
            navigate(`/eventcancel/${eventId}/${participanteventmember.id}`);
          }
        }}
        disabled={!administrator} // 管理者でない場合はボタンを無効にする
        style={{
          cursor: administrator ? "pointer" : " default",
          // backgroundColor: isCurrentUser ? "white" : "white", // 現在のユーザーの場合は緑系、他は通常の色
          // color: isCurrentUser ? "black" : "white", // 現在のユーザーの場合は濃い緑、他は黒
          // border: isCurrentUser ? "1px solid black" : "2px solid black",
        }} // 管理者でない場合はカーソルをnot-allowedにする
      >
        {getAccountName(participanteventmember.memberId)}{" "}
        {/* メンバーのアカウント名を表示 */}
      </button>
    );
  });
  console.log(participantButtons);
  return <div className="participant-list">{participantButtons}</div>;
};
