import React, { cloneElement, useContext, useEffect, useState } from "react";
import "./Calendar.scss";
import { EventContext } from "../Contexts/EventContext";
import { useNavigate } from "react-router-dom";
import { EventMembersContext } from "../Contexts/EventMembersContext";
import { endTimeCalclater } from "../utils/endTimeCalclater";
import { format, formatDate } from "date-fns";
import { addWeeks, isAfter, isBefore } from "date-fns";
import { eventPaticiantCheck } from "../utils/eventPaticiantCheck";
import { MemberContext } from "../Contexts/MemberContext";
import { auth } from "../firebase";
import { findMostFutureEvent } from "../utils/findMostFutureEvent";

const Calendar = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [holidays, setHolidays] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState({});
  const [selectDay, setSelectDay] = useState("");
  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventContent, setNewEventContent] = useState("");
  const { eventList } = useContext(EventContext);
  const { eventmembersList } = useContext(EventMembersContext);
  const navigate = useNavigate();
  const { membersList, currentUserInfo } = useContext(MemberContext);
  const { eventMembersList } = useContext(EventMembersContext);
  const [eventsWithEndTime, setEventsWithEndTime] = useState([]);
  const [latestEvent, setLatestEvent] = useState(null);
  const [hasAlertBeenShown, setHasAlertBeenShown] = useState(false);
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getPrevMonthDays = (year, month) => {
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevMonthYear = month === 0 ? year - 1 : year;
    const prevMonthDays = getDaysInMonth(prevMonthYear, prevMonth);
    const prevMonthFirstDay = getFirstDayOfMonth(prevMonthYear, prevMonth);

    return { prevMonthDays, prevMonthFirstDay };
  };

  const getNextMonthDays = (year, month) => {
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextMonthYear = month === 11 ? year + 1 : year;
    const nextMonthDays = getDaysInMonth(nextMonthYear, nextMonth);
    const nextMonthFirstDay = getFirstDayOfMonth(nextMonthYear, nextMonth);
    return { nextMonthDays, nextMonthFirstDay };
  };

  const daysInMonth = getDaysInMonth(year, month);
  const firstDayOfMonth = getFirstDayOfMonth(year, month);
  const { prevMonthDays, prevMonthFirstDay } = getPrevMonthDays(year, month);
  const { nextMonthDays, nextMonthFirstDay } = getNextMonthDays(year, month);
  const prevRemainDays = firstDayOfMonth;

  const renderDays = () => {
    const days = [];
    let l = 0;

    // 1. 前月の日付の処理
    if (firstDayOfMonth !== 7) {
      for (
        let i = prevMonthDays - firstDayOfMonth + 1;
        i < prevMonthDays + 1;
        i++
      ) {
        l += 1;
        const date = i;
        const targetmonth = "prevMonth";
        let prevmonth = "";
        let prevyear = "";
        if (month === 0) {
          prevmonth = 12;
          prevyear = year - 1;
        } else {
          prevmonth = month;
          prevyear = year;
        }
        const formattedDate = `${prevyear}-${String(prevmonth).padStart(
          2,
          "0"
        )}-${String(date).padStart(2, "0")}`;
        const isHoliday = holidays[formattedDate] || null;
        const eventall = events[formattedDate]; // 前月のイベントを取得

        // イベントにendTimeを追加
        const eventsWithEndTime = eventall
          ? eventall.map((event) => {
              const endTime = endTimeCalclater(event.id, eventList);
              const currentUsetapply = eventPaticiantCheck(
                event.id,
                currentUserInfo,
                eventMembersList
              ); // endTimeを計算
              return { ...event, endTime, currentUsetapply };
            })
          : [];

        days.push({
          day: date,
          isHoliday: !!isHoliday,
          holidayName: isHoliday || null,
          targetmonth: targetmonth,
          formattedDate: formattedDate,
          event: eventsWithEndTime,
          month: "prevMonth",
        });
      }
    }

    // 2. 当月の日付の処理
    for (let i = 1; i <= daysInMonth; i++) {
      const date = i;
      const targetmonth = "nowMonth";
      const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
        date
      ).padStart(2, "0")}`;
      const formattedDatekai = `${year}-${String(month + 1).padStart(
        2,
        "0"
      )}-${String(date).padStart(2, "0")}`;
      const formattedDateForHoliday = `${
        year + Math.floor(month / 12)
      }-${String((month % 12) + 1).padStart(2, "0")}-${String(date).padStart(
        2,
        "0"
      )}`;

      const isHoliday = holidays[formattedDateForHoliday] || null;
      const eventall = events[formattedDatekai];

      // イベントにendTimeを追加
      const eventsWithEndTime = eventall
        ? eventall.map((event) => {
            const endTime = endTimeCalclater(event.id, eventList); // endTimeを計算
            const currentUsetapply = eventPaticiantCheck(
              event.id,
              currentUserInfo,
              eventMembersList
            );
            return { ...event, endTime, currentUsetapply };
          })
        : [];

      //イベントにカレントユーザーが存在するか否かを確認する。

      days.push({
        day: date,
        isHoliday: !!isHoliday,
        holidayName: isHoliday || null,
        targetmonth: targetmonth,
        formattedDate: formattedDatekai,
        event: eventsWithEndTime,
        month: "month",
      });
    }

    // 3. 次月の日付の処理
    for (let i = 1; i <= 6 - nextMonthFirstDay + 1; i++) {
      let nextmonth = 0;
      let nextmonthyear = 0;
      if (daysInMonth + l === 35 || daysInMonth + l === 28) {
        let l = null;
      } else {
        const date = i;
        const targetmonth = "nextMonth";
        if (month === 11) {
          nextmonth = 1;
          nextmonthyear = year + 1;
        } else {
          nextmonth = month + 2;
          nextmonthyear = year;
        }
        const formattedDate = `${nextmonthyear}-${String(nextmonth).padStart(
          2,
          "0"
        )}-${String(date).padStart(2, "0")}`;
        const formattedDatekai = `${year}-${String(month + 2).padStart(
          2,
          "0"
        )}-${String(date).padStart(2, "0")}`;
        const isHoliday = holidays[formattedDate] || null;
        const eventall = events[formattedDate]; // 翌月のイベントを取得

        // イベントにendTimeを追加
        const eventsWithEndTime = eventall
          ? eventall.map((event) => {
              const endTime = endTimeCalclater(event.id, eventList); // endTimeを計算
              const currentUsetapply = eventPaticiantCheck(
                event.id,
                currentUserInfo,
                eventMembersList
              );
              return { ...event, endTime, currentUsetapply };
            })
          : [];
        days.push({
          day: date,
          isHoliday: !!isHoliday,
          holidayName: isHoliday || null,
          targetmonth: targetmonth,
          formattedDate: formattedDatekai,
          event: eventsWithEndTime,
          month: "nextMonth",
        });
      }
    }
    return days;
  };

  const handleYearChange = (direction) => {
    setYear((prev) => prev + direction);
  };

  const handleMonthChange = (direction) => {
    setMonth((prev) => {
      const newMonth = prev + direction;
      if (newMonth < 0) {
        setYear((y) => y - 1);
        return 11;
      } else if (newMonth > 11) {
        setYear((y) => y + 1);
        return 0;
      }
      return newMonth;
    });
  };

  useEffect(() => {
    const fetchHolidays = async (year) => {
      const response = await fetch(
        `https://holidays-jp.github.io/api/v1/date.json`
      );
      if (response.ok) {
        const data = await response.json();
        const filteredHolidays = {};
        for (const [date, name] of Object.entries(data)) {
          if (date.startsWith(year) || date.startsWith(year + 1)) {
            filteredHolidays[date] = name;
          }
        }
        setHolidays(filteredHolidays);
      } else {
        console.error("祝日データの取得に失敗しました。");
      }
    };
    fetchHolidays(year);
  }, [year]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const years = [];
  const yearSelect = () => {
    for (let year = 2100; year >= 1924; year--) {
      years.push(year);
    }
  };
  yearSelect();

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const changeSelectYear = () => {
    setYear(selectedYear);
    setShowModal(false);
  };

  const changeSelectDay = (day) => {
    if (day.targetmonth === "prevMonth") {
      let premonth = "";
      let preyear = "";
      if (month === 0) {
        premonth = 12;
        preyear = year - 1;
      } else {
        premonth = month;
        preyear = year;
      }
      const newSelectDay = `${preyear}-${String(premonth).padStart(
        2,
        "0"
      )}-${String(day.day).padStart(2, "0")}`;
      setSelectDay(newSelectDay);
    } else if (day.targetmonth === "nextMonth") {
      let nextmonth = "";
      let nextyear = "";
      if (month === 11) {
        nextmonth = 1;
        nextyear = year + 1;
      } else {
        nextmonth = month + 2;
        nextyear = year;
      }
      const newSelectDay = `${nextyear}-${String(nextmonth).padStart(
        2,
        "0"
      )}-${String(day.day).padStart(2, "0")}`;
      setSelectDay(newSelectDay);
    } else {
      const newSelectDay = `${year}-${String(month + 1).padStart(
        2,
        "0"
      )}-${String(day.day).padStart(2, "0")}`;
      setSelectDay(newSelectDay);
    }
  };

  // eventListを日付ごとに振り分けます
  useEffect(() => {
    // イベントリストに終了時間を追加する
    const updatedEvents = eventList.map((event) => {
      // 終了時間の計算
      const newEndTime = endTimeCalclater(event.id, eventList);
      return {
        ...event,
        endTime: newEndTime, // 計算した終了時間を追加
      };
    });

    setEventsWithEndTime(updatedEvents);

    // updatedEvents を時間の早い順に並べ替え
    updatedEvents.sort((a, b) => {
      const dateA = new Date(
        `${a.starttime_date} ${a.starttime_hour}:${a.starttime_minute}`
      );
      const dateB = new Date(
        `${b.starttime_date} ${b.starttime_hour}:${b.starttime_minute}`
      );
      return dateA - dateB; // 昇順に並べ替える
    });
    // eventListを日付ごとにマッピング
    const groupedEvents = {};

    // `eventList` をグループ化
    updatedEvents.forEach((event) => {
      const eventDate = event.starttime_date; // イベントの日付
      if (!groupedEvents[eventDate]) {
        groupedEvents[eventDate] = [];
      }
      groupedEvents[eventDate].push(event);
    });

    // 状態を更新
    setEvents(groupedEvents);
  }, [eventList]);

  const handleDayClick = (event) => {
    console.log(event.id);
    navigate(`../eventdetail/${event.id}`);
    // const day = event.day;
    // let targetMonth = "";
    // if (day.month === "nextMonth") {
    //   targetMonth = month + 1;
    //   console.log(day);
    // } else if (day.month === "prevMonth") {
    //   targetMonth = month - 1;
    // } else {
    //   targetMonth = month;
    // }

    // const selectedDate = `${year}-${String(targetMonth + 1).padStart(
    //   2,
    //   "0"
    // )}-${String(day.day).padStart(2, "0")}`;
    // const dayEvents = events[selectedDate];
    // if (dayEvents) {
    //   navigate(`/eventdetail/${dayEvents[0].id}`);
    // } else {
    //   alert(`選択した日付: ${selectedDate}\nイベントはありません。`);
    // }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error("ユーザーがログインしていません。");
      return;
    }

    const userId = user.uid;

    // データが揃っている場合にのみ処理を実行
    if (eventList.length > 0 && eventMembersList.length > 0) {
      // 最も未来のイベントを取得
      const mostFutureEvent = findMostFutureEvent(
        eventList,
        eventMembersList,
        userId,
        isAfter
      );

      setLatestEvent(mostFutureEvent);

      // アラートの表示ロジック
      if (mostFutureEvent && !hasAlertBeenShown) {
        const now = new Date();
        const threeWeeksFromNow = addWeeks(now, 3);

        if (isBefore(new Date(mostFutureEvent.starttime), threeWeeksFromNow)) {
          alert(
            "テニスオフでの参加募集を二週間前に始めますので、参加申し込みは開催３週間前まででお願いいたします。"
          );
          setHasAlertBeenShown(true); // アラートが表示されたことを記録
        }
      }
    }
  }, [eventList, eventMembersList, hasAlertBeenShown]);

  return (
    <>
      <div className="calendar-container">
        <div className="calendar-content">
          <div className="calendar-eventlist">
            <button
              onClick={() => {
                navigate(`/eventlist`);
              }}
            >
              event_list
            </button>
          </div>
          <div className="calendar_side-button">
            {" "}
            <button
              className="calendar_side-button-left"
              onClick={() => handleMonthChange(-1)}
            ></button>{" "}
            <button
              className="calendar_side-button-right"
              onClick={() => handleMonthChange(1)}
            ></button>
          </div>
          <div className="calendar_event_year_change">
            <button
              className="calendar-year-prev"
              onClick={() => handleYearChange(-1)}
            >
              ▲
            </button>
            <button id="year_button" onClick={openModal}>
              {year} / {month + 1 > 12 ? 1 : month + 1}
            </button>

            <button
              className="calendar-year-next"
              onClick={() => handleYearChange(1)}
            >
              ▼
            </button>
          </div>
          <button
            className="calendar-prev"
            onClick={() => handleMonthChange(-1)}
          >
            ◀◀
          </button>
          <button
            className="calendar-next"
            onClick={() => handleMonthChange(1)}
          >
            ▶▶
          </button>
          <div className="calendar-field">
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
              (day, index) => (
                <div
                  className={`calendar-label ${
                    index === 0 ? "sunday" : index === 6 ? "saturday" : ""
                  }`}
                  key={day}
                >
                  {day}
                </div>
              )
            )}
            {renderDays().map((day, index) => {
              const dayOfWeek = index % 7;
              const dateKey = `${year}-${String(month + 1).padStart(
                2,
                "0"
              )}-${String(day.date).padStart(2, "0")}`;
              return (
                <div className="calendar-cell">
                  <div
                    className={`calendar-cell-inner ${
                      day.day && dayOfWeek === 0
                        ? "sunday"
                        : day.day && dayOfWeek === 6
                        ? "saturday"
                        : "weekday"
                    } ${day.isHoliday ? "holiday" : ""} ${
                      day.targetmonth === "prevMonth" ? "prevmonth" : ""
                    } ${day.targetmonth === "nextMonth" ? "nextmonth" : ""}`}
                    key={index}
                    // onClick={() => day.day && changeSelectDay(day)}
                  >
                    {day.day || ""}
                  </div>
                  <div className="holiday-name">{day.holidayName || ""}</div>
                  <div
                    className={`calendar-events ${
                      day.event.length > 2 ? "scllor" : ""
                    }`}
                  >
                    {day.event &&
                      day.event.map((event, index) => (
                        <div className="calendar-event" key={index}>
                          <div
                            className="calendar-event-inner"
                            onClick={() => handleDayClick(event)}
                          >
                            <div className="event_image">
                              <img
                                src={
                                  event.currentUsetapply
                                    ? "/images/ballaplly.png"
                                    : "/images/ball.png"
                                }
                                alt="テ"
                                className={
                                  event.currentUsetapply
                                    ? "event-image-aplly"
                                    : "event-image"
                                }
                              />
                            </div>

                            <div className="calendar_event_schedule">
                              <div className="calendar_event_start_time">
                                {format(new Date(event.starttime), "HH:mm") ||
                                  ""}{" "}
                                ~
                              </div>
                              <div className="calendar_event_endtime">
                                {format(new Date(event.endTime), "HH:mm") || ""}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>

          {selectDay && (
            <div className="calendar-modal-input-field">
              <h3>{`${selectDay}の予定`}</h3>
              <input
                type="text"
                value={newEventTitle}
                onChange={(e) => setNewEventTitle(e.target.value)}
                placeholder="タイトル入力（カレンダー表示は3文字のみ）"
              />
              <textarea
                type="text"
                value={newEventContent}
                onChange={(e) => setNewEventContent(e.target.value)}
                placeholder="予定を入力"
              />
              <div className="calendar-modal-input-button">
                {/* <button onClick={handleAddEvent}>追加</button> */}
                <button onClick={() => setSelectDay(null)}>キャンセル</button>
              </div>
            </div>
          )}

          <div id="modal" style={{ display: showModal ? "" : "none" }}>
            <div className="modal-container">
              <select
                id="year-select"
                value={selectedYear}
                onChange={handleChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <button id="modal-ok" onClick={changeSelectYear}>
                OK
              </button>
              <button id="modal-cancel" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
          <div className="selected-day">
            {selectDay && <p>選択した日: {selectDay}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
