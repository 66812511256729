import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Step3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [input3, setInput3] = useState("");

  useEffect(() => {
    if (location.state?.input2) {
      // 以前の入力を保持することができます
    }
  }, [location]);

  const handleChange = (e) => setInput3(e.target.value);

  const handleNext = () => {
    navigate("/submit", { input3 });
  };

  return (
    <div>
      <h2>Step 3</h2>
      <input
        type="text"
        value={input3}
        onChange={handleChange}
        placeholder="Enter Input 3"
      />
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default Step3;
