import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EventSelect.scss";
import { EventContext } from "../Contexts/EventContext";

const EventSelect = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { eventList } = useContext(EventContext);

  // Firestoreからイベントデータを取得
  useEffect(() => {
    const fetchData = async () => {
      eventList.sort((a, b) => b.starttime - a.starttime); // 最新順にソート
      setEvents(eventList);
    };

    fetchData();
  }, []);

  // イベント再利用ハンドラー
  const handleCreateEvent = (eventId) => {
    navigate(`/eventinfo/${eventId}/eventreuse`);
  };

  return (
    <div className="eventselect-container">
      <div className="eventselect-content">
        <div className="eventselect-field">
          {events.length === 0 ? (
            <div className="no-events">
              <p>登録されたイベントはありません。</p>
              <button onClick={() => navigate("/eventform")}>
                新規日程入力
              </button>
            </div>
          ) : (
            <>
              <label>再利用可能なイベント一覧</label>
              <table>
                <thead>
                  <tr>
                    <th className="event-select-date">開催日時</th>
                    <th className="event-select-title">タイトル</th>
                    <th className="event-select-location">開催場所</th>
                    <th className="event-select-reuse">再利用</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event, index) => (
                    <tr key={event.id}>
                      <td>
                        {format(event.starttime, "M/d（E）HH:mm", {
                          locale: ja,
                        })}
                      </td>
                      <td>{event.title}</td>
                      <td>{event.site_region}</td>
                      <td>
                        <button
                          className="reuse-button"
                          onClick={() => handleCreateEvent(event.id)}
                        >
                          再利用
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventSelect;
