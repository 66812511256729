// src/utils/fetchEventMembersData.js

import {
  collection,
  onSnapshot,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

export const subscribeToEventMembersData = (callback) => {
  try {
    const oneWeekAgo = new Date();
    //何日前かを指定今回は１日
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 30);
    const oneWeekAgoTimestamp = Timestamp.fromDate(oneWeekAgo);
    const eventMembersCollection = collection(db, "event_members");
    // クエリを作成：created_atが上記日以前を取得
    const eventmembersQuery = query(
      eventMembersCollection,
      where("created_at", ">=", oneWeekAgoTimestamp) // created_atが一週間前以降
    );

    // Real-time listener for changes in the "members" collection
    const unsubscribe = onSnapshot(eventmembersQuery, (snapshot) => {
      const eventMembersList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(eventMembersList); // Call the callback with the updated members list
    });

    // Return the unsubscribe function to stop listening to changes
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching members data:", error);
    return () => {}; // Return a no-op function in case of error
  }
};
