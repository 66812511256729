import { db } from "../firebase"; // Firebaseの設定ファイルをインポート
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
} from "firebase/firestore";
import { EmailSenderBase } from "../utils/EmailSenderBase";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
export const JointEvent = async (
  event,
  eventId,
  events,
  participantCounts,
  membersList,
  setEventMembers,
  setUserEventParticipation,
  setResponseMessage,
  currentUserInfo,
  currentUser,
  navigate
) => {
  const userId = currentUser?.uid;
  const useradmin = currentUserInfo?.admin;
  const useradministrator = currentUserInfo?.administrator;
  const eventMembersQuery = query(
    collection(db, "event_members"),
    where("eventId", "==", eventId),
    where("memberId", "==", userId)
  );

  const querySnapshot = await getDocs(eventMembersQuery);

  if (!querySnapshot.empty) {
    alert("このイベントにはすでに参加しています。");
    return;
  }

  const eventDetail = events.find((e) => e.id === eventId);
  if (!eventDetail) {
    alert("イベントが見つかりません");
    return;
  }

  const currentParticipantsCount = participantCounts[eventId] || 0;

  if (currentParticipantsCount >= eventDetail.capacity) {
    alert("定員締め切りのため、参加できません。");
    return;
  }

  const user = membersList.find((member) => member.author.id === userId);
  if (!user) {
    alert(
      "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
    );
    navigate("/member");
    return;
  }
  const accountname = user.accountname;
  console.log("保存するデータ:", {
    eventId: eventId,
    memberId: userId,
    accountname: accountname,
    admin: useradmin,
    administrator: useradministrator,
    created_at: new Date(),
    updated_at: new Date(),
  });

  const docRef = await addDoc(collection(db, "event_members"), {
    eventId: eventId,
    memberId: userId,
    accountname: accountname,
    ad: useradmin,
    admi: useradministrator,
    created_at: new Date(),
    updated_at: new Date(),
  });

  await addDoc(collection(doc(db, "events", eventId), "members"), {
    memberId: userId,
    accountname: accountname,
    useradmin: useradmin,
    useradministrator: useradministrator,
    created_at: new Date(),
    updated_at: new Date(),
  });

  //申し込みした日が２週間より前か否かを確認
  // 本日の日付を取得（開始時間を 00:00:00 にリセット）
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (!event) {
    return <p>Loading...</p>;
  }

  const startTime = new Date(event.starttime);
  startTime.setHours(0, 0, 0, 0); // 時刻をリセット
  const daysDifference = (startTime - today) / (1000 * 60 * 60 * 24);
  const twoWeeksFromNow = daysDifference - 14 > 1;

  console.log(twoWeeksFromNow);
  //テニスオフ募集開始以降の場合はメールを送信
  if (!twoWeeksFromNow) {
    // console.log("テニスオフだよ" + event.tennis_off);
    const subject = "二週間前より以降の参加表明";
    const emailContent = [
      {
        email: ["matsumo33333@gmail.com"],
        text: `二週間前より以降の${format(event.starttime, "M /d（E）HH:mm ", {
          locale: ja,
        })}の${event.title}参加表明が${
          currentUserInfo.accountname
        }さんからありましたので、テニスオフの募集調整等の対応をしてください。
            \n\nhttps://raising2024-7e234.web.app/eventdetail/${eventId}`,
      },
    ];
    console.log("送信するメール" + emailContent);
    emailContent.forEach(({ email, text }) => {
      EmailSenderBase([email], subject, text, setResponseMessage);
    });
    alert("参加申し込みの連絡メールが送信されました。");
  }

  const docId = docRef.id;

  setEventMembers((prevEventMembers) => [
    ...prevEventMembers,
    { eventId, memberId: userId, accountname, id: docId },
  ]);

  setUserEventParticipation((prevParticipation) => ({
    ...prevParticipation,
    [eventId]: true,
  }));

  const eventMembersCollection = collection(db, "event_members");
  const eventMembersSnapshot = await getDocs(eventMembersCollection);
  const eventMembersList = eventMembersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  setEventMembers(eventMembersList);
};
