import React, { useContext, useEffect, useRef, useState } from "react";
import { db, storage } from "../firebase";
import axios from "axios";
import ReactPlayer from "react-player";
import "./PostList.scss";

import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  deleteDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import CreateComment from "./CreateComment";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { MemberContext } from "../Contexts/MemberContext";
import { EditComment } from "./EditComment";

export const Comments = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const { currentUserInfo } = useContext(MemberContext);
  const postaccountname = currentUserInfo?.accountname;
  const postaccountId = currentUserInfo?.id;
  const [editingPosts, setEditingPosts] = useState({});
  const currentaccountId = currentUserInfo?.author.id;
  const currentaccountAdmin = currentUserInfo?.admin;
  const currentaccountAdministrator = currentUserInfo?.administrator;
  const [canPostEdit, setCanPostEdit] = useState(false);
  const [canComentEdit, setCanComentEdit] = useState(false);
  const commentPostAccountName = "";

  useEffect(() => {
    const q = query(
      collection(db, "posts", postId, "comments"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setComments(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [postId]);

  const toggleEdit = (commentId) => {
    setEditingPosts((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // クリックされた投稿だけトグルする
    }));
  };

  // コメント削除
  const handleDeleteComment = async (commentId) => {
    const commentDocRef = doc(db, "posts", postId, "comments", commentId);
    await deleteDoc(commentDocRef);
  };

  // コメント編集
  const handleEditComment = async (commentId, newComment) => {
    try {
      const commentDocRef = doc(db, "posts", postId, "comments", commentId);
      await updateDoc(commentDocRef, {
        comment: newComment,
        updatedAt: serverTimestamp(),
      });
      console.log("コメントを更新しました");
      setEditingPosts(false);
    } catch (error) {
      console.error("コメントの更新に失敗しました:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    const now = new Date();

    // 時間差を計算（ミリ秒）
    const diff = now - date;

    // ミリ秒を時間単位に変換
    const diffHours = Math.floor(diff / (1000 * 60 * 60)); // 時間差
    const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // 分差

    // 時間差が1時間未満なら分差で表示、それ以上は時間差で表示
    if (diffHours > 0) {
      return `${diffHours}時間前`;
    } else if (diffMinutes > 0) {
      return `${diffMinutes}分前`;
    } else {
      return "数秒前";
    }
  };

  return (
    <div className="postlist_comment_container">
      <div className="postlist_comment_conent">
        {" "}
        <div className="postlist_comment_field">
          {" "}
          {comments.map((comment) => (
            <div className="postlist_comment_text" key={comment.id}>
              <p className="postlist_comment_autor">
                @
                {comment.commentpostaccountname &&
                  comment.commentpostaccountname}{" "}
                {comment.createdAt && formatTimestamp(comment.createdAt)}
                {comment.updatedAt && "(修正済）"}
              </p>
              <p className="postlist_comment_comment">{comment.comment}</p>

              {editingPosts[comment.id] ? (
                <>
                  <div className="postlist_comment_comment_buttons">
                    {" "}
                    {/* 編集と削除ボタン */}
                    <EditComment
                      commentId={comment.id}
                      existingComment={comment.comment}
                      handleEdit={handleEditComment}
                      handleDeleteComment={handleDeleteComment}
                      setEditingPosts={setEditingPosts}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <button
                onClick={() => toggleEdit(comment.id)} // クリックでトグル
                className="comment_toggle-edit-button"
              >
                {!(
                  currentaccountAdministrator ||
                  currentaccountId === comment.commentpostaccountId
                )
                  ? ""
                  : "⋮"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
