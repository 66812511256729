import { useEffect, useState } from "react";
import "./Home.scss";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Calendar from "./Calendar";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [displayImages, setDisplayImages] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [eventReports, setEventReports] = useState([]);
  const [memberExchanges, setMemberExchanges] = useState([]);
  const [others, setOthers] = useState([]);
  // const [otherReports, setOtherReports] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [maxChars, setMaxChars] = useState(10);

  const queries = [
    {
      query: query(
        collection(db, "posts"),
        where("section.event_report", "==", true),
        orderBy("section.event_report"),
        orderBy("createdAt", "desc"),
        limit(5)
      ),
      setState: setEventReports,
    },
    {
      query: query(
        collection(db, "posts"),
        where("section.member_exchange", "==", true),
        orderBy("section.member_exchange"),
        orderBy("createdAt", "desc"),
        limit(5)
      ),
      setState: setMemberExchanges,
    },
    {
      query: query(
        collection(db, "posts"),
        where("section.recommendations", "==", true),
        orderBy("section.recommendations"),
        orderBy("createdAt", "desc"),
        limit(5)
      ),
      setState: setRecommendations,
    },
    {
      query: query(
        collection(db, "posts"),
        where("section.others", "==", true),
        orderBy("section.others"),
        orderBy("createdAt", "desc"),
        limit(5)
      ),
      setState: setOthers,
    },
  ];

  useEffect(() => {
    const unsubscribeFuncs = queries.map(({ query, setState }) =>
      onSnapshot(query, (snapshot) => {
        setState(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      })
    );

    return () => {
      unsubscribeFuncs.forEach((unsubscribe) => unsubscribe());
    };
  }, []);

  useEffect(() => {
    // 4つの配列を統合して新しい配列を作成
    setAllPosts([
      ...eventReports,
      ...memberExchanges,
      ...recommendations,
      ...others,
    ]);
  }, [eventReports, memberExchanges, recommendations, others]);

  // console.log(posts);
  // 24枚の画像を slides 配列に追加
  const slides = Array.from({ length: 24 }, (_, index) => ({
    src: `./images/img${index + 1}.jpg`,
    alt: `Slide ${index + 1}`,
  }));

  // ランダムに6枚の画像を選択する関数
  const getRandomImages = () => {
    const shuffledSlides = [...slides].sort(() => 0.5 - Math.random());
    return shuffledSlides.slice(0, 18);
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsActive(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const updateImages = () => {
      setDisplayImages(getRandomImages());
    };

    updateImages(); // 初回の画像選択

    const interval = setInterval(updateImages, 5000); // 5秒ごとに画像を更新

    return () => clearInterval(interval);
  }, [slides.length]);

  const headlineText = "RAISING 2004";

  const handleShowBlog = (postId) => {
    const postData = allPosts.find((post) => post.id === postId);
    console.log(posts);
    console.log(postData);
    const trueSections = Object.entries(postData?.section || {})
      .filter(([key, value]) => value === true)
      .map(([key]) => key);

    const trueSectionsString =
      trueSections.length === 1 ? trueSections[0] : trueSections.join(", ");
    console.log(trueSectionsString);

    navigate(`/postlist`, {
      state: { trueSectionsString: trueSectionsString },
    });
    // console.log(postId);
  };

  // 画面幅に応じた文字数を設定
  useEffect(() => {
    // 画面幅に応じた文字数を設定
    const updateMaxChars = () => {
      if (window.innerWidth < 576) {
        setMaxChars(20); // 画面幅400px未満なら5文字
      } else if (window.innerWidth < 768) {
        setMaxChars(20); // 画面幅768px未満なら8文字
      } else if (window.innerWidth < 1400) {
        setMaxChars(30); // 画面幅768px未満なら8文字
      } else {
        setMaxChars(60); // それ以上なら10文字
      }
    };

    updateMaxChars(); // 初回実行
    window.addEventListener("resize", updateMaxChars); // リサイズ時に更新

    return () => {
      window.removeEventListener("resize", updateMaxChars); // クリーンアップ
    };
  }, []);

  const combinedText = (postTitle, postContent, maxChars) => {
    const text = postTitle + "：" + postContent;
    return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
  };

  return (
    <>
      <div className="home_container">
        <div className="home_container_top">
          <div className="honoka headline">
            <div className={`honoka text ${isActive ? "is-active" : ""}`}>
              {headlineText.split("").map((char, index) => (
                <span
                  className={`honoka char ${char === " " ? "whitespace" : ""}`}
                  style={{ "--char-index": index }}
                  key={index}
                >
                  {char}
                </span>
              ))}
            </div>

            <div className="home-text"></div>
          </div>
          <div className="home-image-container">
            <div className="images-pa">
              {displayImages.map((slide, index) => (
                <div
                  className="images"
                  key={index}
                  style={{
                    backgroundImage: `url(${slide.src})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className="home_container_main">
          <div className="home_content_left">
            <div className="home_content_left_inner">
              {" "}
              <div className="home_content_left_title">会の歩み（工事中）</div>
              <div className="home_content_left_title">
                会員の戦歴（工事中）
              </div>
              <div className="home_content_left_title">会の？？（工事中）</div>
              <div className="home_content_left_title">
                会の？？戦歴（工事中）
              </div>
            </div>
          </div>{" "}
          <div className="home_content_center">
            {" "}
            <div className="home_content_inner">
              <div className="home_field">
                <div className="home_field_event_report">
                  <div className="home_field_event_title_event_report">
                    <span className="home_field_event_title_text">
                      イベント関係
                    </span>
                  </div>{" "}
                  {eventReports.map((post, index) => (
                    <>
                      <div key={post.id || index} className="home_post_content">
                        {" "}
                        <span
                          className="home_post_title"
                          onClick={() => {
                            handleShowBlog(post.id);
                          }}
                        >
                          {combinedText(post.title, post.content, maxChars)}
                        </span>
                      </div>
                    </>
                  ))}
                </div>
                <div className="home_field_event_member_exchange">
                  <div className="home_field_event_title_member_exchange">
                    <span className="home_field_event_title_text">
                      会の情報
                    </span>
                  </div>
                  {memberExchanges.map((post, index) => (
                    <>
                      <div key={post.id || index} className="home_post_content">
                        <span
                          className="home_post_title"
                          onClick={() => {
                            handleShowBlog(post.id);
                          }}
                        >
                          {combinedText(post.title, post.content, maxChars)}
                        </span>
                      </div>
                    </>
                  ))}
                </div>
                <div className="home_field_event_recommendations">
                  <div className="home_field_event_title_recommendations">
                    <span className="home_field_event_title_text">
                      テニス情報
                    </span>
                  </div>
                  {recommendations.map((post, index) => (
                    <>
                      <div key={post.id || index} className="home_post_content">
                        {" "}
                        <span
                          className="home_post_title"
                          onClick={() => {
                            handleShowBlog(post.id);
                          }}
                        >
                          {combinedText(post.title, post.content, maxChars)}
                        </span>
                      </div>
                    </>
                  ))}
                </div>
                <div className="home_field_event_others">
                  <div className="home_field_event_title_others">
                    <span className="home_field_event_title_text">
                      よもやま話
                    </span>
                  </div>
                  {others.map((post, index) => (
                    <>
                      <div key={post.id || index} className="home_post_content">
                        <span
                          className="home_post_title"
                          onClick={() => {
                            handleShowBlog(post.id);
                          }}
                        >
                          {combinedText(post.title, post.content, maxChars)}
                          {/* {post.title}：{post.section.others} */}
                        </span>
                      </div>
                    </>
                  ))}
                </div>{" "}
              </div>
            </div>{" "}
          </div>
          <div className="home_content_right">
            {" "}
            <div className="home_content_right_top">
              {" "}
              <div>
                <Calendar />
              </div>
            </div>
            {/* <div className="home_content_right_under">
              <h4>工事中です</h4>
            </div> */}
          </div>
        </div>

        <div className="home_content">
          <div></div>
        </div>
      </div>
      {/* <div className="home_container_bottom">
        <h4>工事中です</h4>
      </div> */}
    </>
  );
};

export default Home;
