import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MemberContext } from "../Contexts/MemberContext";
import "./MemberDetail.scss";

const MemberDetail = () => {
  const { membersList } = useContext(MemberContext);
  const [member, setMember] = useState(null);
  const [fields, setFields] = useState([]);
  const [isShuffling, setIsShuffling] = useState(true); // ★ シャッフルのON/OFFを管理
  const location = useLocation();
  const { authorId } = location.state || {};
  const { id } = useParams();
  const fieldRefs = useRef({});
  const navigate = useNavigate();
  const intervalRef = useRef(null); // ★ intervalを管理するRef

  useEffect(() => {
    if (authorId && membersList.length > 0) {
      const foundMember = membersList.find(
        (m) => m.author && m.author.id === authorId
      );
      if (foundMember) {
        setMember(foundMember);
        setFields(shuffleFields(foundMember));
      }
    }
  }, [authorId, membersList]);

  const shuffleFields = (member) => {
    const fieldArray = [
      {
        key: "tennisExperience",
        label: "テニス歴",
        value: member.tennisExperience,
      },
      { key: "racquetUsed", label: "ラケット遍歴", value: member.racquetUsed },
      {
        key: "favoriteShot",
        label: "好きなショット、得意なショット",
        value: member.favoriteShot,
      },
      { key: "strengths", label: "テニスの課題", value: member.strengths },
      {
        key: "playerStyle",
        label: "好きなテニス選手のプレースタイル",
        value: member.playerStyle,
      },
      {
        key: "mainAchievements",
        label: "主な戦績（適宜管理人が加筆してます)",
        value: member.mainAchievements,
      },
      {
        key: "hobbiesSkills",
        label: "テニス以外の趣味や特技、苦手なこと",
        value: member.hobbiesSkills,
      },
      { key: "struggles", label: "テニス以外の拘り", value: member.struggles },
    ];
    return fieldArray.sort(() => Math.random() - 0.5);
  };

  //★ シャッフルの開始・停止を制御
  useEffect(() => {
    if (isShuffling) {
      intervalRef.current = setInterval(() => {
        setFields((prevFields) => [
          ...prevFields.sort(() => Math.random() - 0.5),
        ]);
      }, 5000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isShuffling]);

  useEffect(() => {
    Object.keys(fieldRefs.current).forEach((key) => {
      const fieldElement = fieldRefs.current[key];
      if (fieldElement) {
        const height = fieldElement.clientHeight;
        if (height > 500) {
          fieldElement.classList.add("superlarge");
        } else if (height > 300) {
          fieldElement.classList.add("large");
        } else if (height > 100) {
          fieldElement.classList.add("medium");
        } else {
          fieldElement.classList.add("small");
        }
      }
    });
  }, [fields]);

  if (!member) {
    return <p>メンバー情報が見つかりません。</p>;
  }

  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
      document
        .querySelectorAll(".member_details_field")
        .forEach((el, index) => {
          setTimeout(() => {
            el.classList.add("animate");
          }, index * 300);
        });
    }, 700);
  });

  return (
    <div className="member_details_container">
      <div className="member_detials_title">
        <h2>{member.accountname}さんのプロフィール</h2>
        <button
          className="shuffle-button"
          onClick={() => setIsShuffling((prev) => !prev)}
        >
          {isShuffling ? `move stop` : "move start"}
        </button>
        <div className="member_detials-memberlist">
          <button
            className="member_detials-button"
            onClick={() => {
              navigate(`/memberlist2`);
            }}
          >
            member
            <br />
            list
          </button>
        </div>
      </div>

      <div className="member_details_content">
        {fields.map((field, index) => (
          <div
            key={field.key}
            className="member_details_field animate"
            ref={(el) => (fieldRefs.current[field.key] = el)}
          >
            <span className="member_detail_field_lable">{field.label}</span>
            <div className="member_detail_field_content">
              <span className="member_detail_field_content_inner">
                <p>{field.value}</p>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberDetail;
