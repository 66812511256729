import React, { useContext, useEffect, useRef, useState } from "react";
import { db, storage } from "../firebase";
import axios from "axios";
import ReactPlayer from "react-player";
import "./PostList.scss";

import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  deleteDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import CreateComment from "./CreateComment";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { MemberContext } from "../Contexts/MemberContext";
import { Comments } from "./Comments";
import { PostEditForm } from "./PostEditForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { id } from "date-fns/locale";

const PostList = () => {
  // const { Id } = useParams();
  const [posts, setPosts] = useState([]);
  const [videoTitles, setVideoTitles] = useState({});
  const { currentUserInfo } = useContext(MemberContext);
  const [editingPosts, setEditingPosts] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const currentaccountId = currentUserInfo?.id;
  const currentaccountAdministrator = currentUserInfo?.administrator;
  const [selected, setSelected] = useState("event_report");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedSection, setSelectedSection] = useState(false);
  const location = useLocation();
  const { createsection, trueSectionsString } = location.state || {};

  console.log(trueSectionsString);

  let Id;
  if (trueSectionsString) {
    Id = trueSectionsString;
  } else if (createsection) {
    Id = createsection;
  }

  useEffect(() => {
    if (!isAdmin) {
      alert("ブログ等の閲覧は会員だけができます。");
      navigate("/eventlist");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    const q = query(collection(db, "posts"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setPosts(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // YouTube URLのタイトルを取得する関数を呼び出し
    posts.forEach((post) => {
      if (post.youtubeUrl) {
        fetchYoutubeTitle(post.youtubeUrl);
      }
    });
  }, [posts]);

  useEffect(() => {
    if (selectedSection) {
      setFilteredPosts(posts);
    } else {
      if (selected === "event_report") {
        setFilteredPosts(
          posts.filter((post) => post.section?.["event_report"] === true)
        );
      } else if (selected === "member_exchange") {
        console.log("会員情報");
        setFilteredPosts(
          posts.filter((post) => post.section?.["member_exchange"] === true)
        );
      } else if (selected === "recommendations") {
        setFilteredPosts(
          posts.filter((post) => post.section?.["recommendations"] === true)
        );
      } else if (selected === "others") {
        console.log("お薦め");
        setFilteredPosts(
          posts.filter((post) => post.section?.["others"] === true)
        );
      } else {
        console.log("該当するセクションがありません");
      }
    }
  }, [posts, selectedSection, selected]);

  useEffect(() => {
    if (Id) {
      setSelected(Id);
      setSelectedSection(false);
    }
  }, []);

  const toggleEdit = (postId) => {
    setEditingPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId], // クリックされた投稿だけトグルする
    }));
  };
  const fetchYoutubeTitle = async (url) => {
    const videoId = extractVideoId(url); // URLから動画IDを抽出
    if (!videoId) return;

    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=YOUR_API_KEY`
      );
      const title = response.data.items[0].snippet.title;
      setVideoTitles((prev) => ({
        ...prev,
        [videoId]: title, // 動画IDとタイトルを保存
      }));
    } catch (error) {
      console.error("YouTube APIのエラー:", error);
    }
  };

  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|\S*?[?&]v=|(?:v|e(?:mbed)?)\/))([^"&?\/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const options = [
    { jp: "イベント報告", en: "event_report" },
    { jp: "会の情報", en: "member_exchange" },
    { jp: "テニス情報", en: "recommendations" },
    { jp: "よもやま話", en: "others" },
  ];

  // 投稿削除ｓs
  const handleDeletePost = async (postId) => {
    const deletepost = posts.find((post) => (post.id = postId));
    const isConfirmed = window.confirm(
      `本当に”${deletepost.title}”の投稿を削除しますか？`
    );

    if (isConfirmed) {
      const deleteTargetImageUrl = posts.find((post) => post.id === postId);
      console.log();
      if (isConfirmed) {
        const deleteTargetImageUrl = posts.find((post) => post.id === postId);
        console.log(deleteTargetImageUrl.imageUrl);

        const filePath = deleteTargetImageUrl.imageUrl.split("o/")[1]; // パスの取得
        console.log("File Path: ", filePath);
        const storageRef = ref(storage, `blog-images/${filePath}`);
        if (deleteTargetImageUrl && deleteTargetImageUrl.imageUrl) {
          // URLからファイルパス部分を抽出（クエリパラメータを除去）
          const imagePath = deleteTargetImageUrl.imageUrl.split("?")[0]; // クエリ部分を削除
          const decodedPath = decodeURIComponent(imagePath); // パスをデコード
          const filePath = decodedPath.split("o/")[1]; // Firebaseのパス部分を抽出

          // 'blog-images'が重複していないか確認し、修正
          const finalPath = filePath.replace(/^blog-images\//, ""); // 重複部分を削除

          const storageRef = ref(storage, `blog-images/${finalPath}`); // 正しいストレージ参照を作成
          try {
            await deleteObject(storageRef); // 画像を削除
            alert("画像が削除されました");
          } catch (error) {
            console.error("画像の削除に失敗しました: ", error.message);
            alert("画像の削除に失敗しました");
          }
        }
      } else {
        alert("削除がキャンセルされました");
      }

      // const postDocRef = doc(db, "posts", postId);
      // await deleteDoc(postDocRef);

      alert("投稿が削除されました");
    } else {
      alert("削除がキャンセルされました");
    }
  };

  // 投稿編集
  const handleEditPost = async (
    postId,
    newTitle,
    newSection,
    newContent,
    newParticipant,
    newImageUrl,
    newYoutubeUrl
  ) => {
    setLoading(true);
    const postDocRef = doc(db, "posts", postId);

    // 既存のドキュメントから画像URLを取得
    const postDoc = await getDoc(postDocRef);
    let currentImageUrl = postDoc.data()?.imageUrl; // 現在の画像URL

    if (currentImageUrl) {
      const storageRef = ref(storage, currentImageUrl); // 現在の画像の参照を作成
      await deleteObject(storageRef); // 画像を削除
    }

    let imageUrl = newImageUrl; // 新しい画像URL

    if (imageUrl instanceof Blob) {
      // 画像を Firebase Storage にアップロード
      const storageRef = ref(storage, `images/${postId}`);
      await uploadBytes(storageRef, imageUrl);
      imageUrl = await getDownloadURL(storageRef); // URL を取得
    }

    await updateDoc(postDocRef, {
      title: newTitle,
      section: newSection,
      content: newContent,
      participant: newParticipant ?? "",
      imageUrl: imageUrl ?? "",
      youtubeUrl: newYoutubeUrl ?? "",
      updatedAt: serverTimestamp(),
    });
    setEditingPosts(false);
    setLoading(false);
  };

  const formatTimestamp = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleString("ja-JP", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleSelect = (en) => {
    setSelected(en);
    console.log(en);
    setSelectedSection(false);
  };

  const handleAllSelect = () => {
    setSelectedSection(true);
    console.log(selectedSection);
  };

  return (
    <>
      <div className="postlist_container">
        <div className="postlist_content1">
          <div
            className={`postlist_field0 postlist_color_${
              selected === "event_report"
                ? "event_report"
                : selected === "member_exchange"
                ? "member_exchange"
                : selected === "recommendations"
                ? "recommendations"
                : selected === "others"
                ? "others"
                : ""
            } `}
          >
            <div className="postlist_title_select">
              <div className="postlist_title_select_label">
                {/* <div
                className="checkbox-label"
                onClick={() => {
                  handleAllSelect();
                }}
              >
                全て選択
              </div> */}
                {options.map(({ en, jp }) => (
                  <label
                    key={en}
                    className={`checkbox-label_${
                      selected === en ? selected : ""
                    }`}
                    onClick={() => {
                      handleSelect(en);
                    }}
                  >
                    {jp}
                  </label>
                ))}{" "}
                <div className="postlist_home">
                  <span
                    className="postlist_post_home"
                    onClick={() => {
                      navigate(`/`);
                    }}
                  >
                    return
                    <br />
                    home
                  </span>
                </div>
              </div>
              <div className="postlist_post">
                <span
                  className="postlist_post_title"
                  onClick={() => {
                    navigate(`/createpost`);
                  }}
                >
                  投稿
                </span>
              </div>
            </div>
          </div>
          <div className="postlist_content">
            {loading && <div className="postlist_loding">Loding...</div>}{" "}
            {filteredPosts.map((post) => (
              <>
                <div
                  className={`postlist_field postlist_color_${
                    post.section.event_report
                      ? "event_report"
                      : post.section.member_exchange
                      ? "member_exchange"
                      : post.section.recommendations
                      ? "recommendations"
                      : post.section.others
                      ? "others"
                      : ""
                  } `}
                >
                  <div className="postlist_field_inner_title">
                    <h3>{post.title}</h3>
                    {!editingPosts[post.id] && (
                      <>
                        <div className="postlist_postusername">
                          <p>
                            {post.postaccountname}
                            <br />
                            {!post.participant &&
                              formatTimestamp(post.createdAt)}
                          </p>
                          {currentaccountAdministrator ? (
                            // ||
                            // currentaccountId === post.postaccountId
                            <button
                              onClick={() => toggleEdit(post.id)}
                              className="toggle-edit-button"
                            >
                              ⋮
                            </button>
                          ) : (
                            `　`
                          )}
                        </div>
                      </>
                    )}
                    <div className="postlist_field_inner_title_button">
                      {editingPosts[post.id] && (
                        <>
                          <PostEditForm
                            postId={post.id}
                            existingTitle={post.title}
                            existingSection={post.section}
                            existingContent={post.content}
                            existingParticipant={post.participant}
                            existingYoutubeUrl={post.youtubeUrl}
                            existingNewImage={post.imageUrl}
                            handleEditPost={handleEditPost}
                            setEditingPosts={setEditingPosts}
                          />
                          <button
                            onClick={() => setEditingPosts(false)}
                            className="postlist_field_content_cancel2_button"
                          >
                            キャンセル
                          </button>
                          <button
                            onClick={() => handleDeletePost(post.id)}
                            className="postlist_field_content_delete_button"
                          >
                            削除
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="postlist_field_content" key={post.id}>
                    {post.imageUrl && (
                      <img
                        className="postlist_field_content_image"
                        src={post.imageUrl}
                        alt={post.title}
                      />
                    )}

                    {post.youtubeUrl && (
                      <div className="postlist_field_content_youtube">
                        <ReactPlayer
                          url={post.youtubeUrl}
                          width="100%"
                          height="auto"
                          controls={true}
                        />
                        {/* YouTube動画のタイトルを表示 */}
                      </div>
                    )}
                    <div className="postlist_field_content_text">
                      <p className="postlist_field_content_text_text">
                        {post.content}
                        <br />
                      </p>
                      {post.participant && (
                        <p>
                          {" "}
                          <span className="postlist_field_content_text_text_small">
                            　参加者({post.participant})
                          </span>
                        </p>
                      )}

                      <div className="postlist_field_content_comment">
                        <Comments postId={post.id} />
                        <CreateComment
                          postId={post.id}
                          postAccountId={post.postaccountId}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </>
            ))}
          </div>
          {/* <div className="postlist_nextpage">次へ</div> */}
        </div>
        {/* {posts.length === 0 ? <p>投稿がありません</p> : null} */}
      </div>
    </>
  );
};

export default PostList;
