import React, { useContext, useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { MemberContext } from "../Contexts/MemberContext";
import "./CreateComment.scss";

const CreateComment = ({ postId, postAccountId }) => {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const { membersList, currentUserInfo } = useContext(MemberContext);
  const textareaRef = useRef(null);

  const commentpostaccountId = currentUserInfo?.author.id;
  const commentpostaccountname = currentUserInfo?.accountname;

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "16px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // 内容に合わせる
    }
  }, [comment]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // コメントを投稿する
    await addDoc(collection(db, "posts", postId, "comments"), {
      comment,
      commentpostaccountId,
      commentpostaccountname,
      createdAt: serverTimestamp(),
    });

    setComment("");
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="createcomment_texterea">
      <textarea
        className="createcomment_texterea_add"
        ref={textareaRef}
        placeholder="コメントを追加"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        required
      />

      <button
        className="createcomment_texterea_add_button"
        type="submit"
        disabled={loading}
      >
        {loading ? "送信中..." : "コメントを投稿"}
      </button>
    </form>
  );
};

export default CreateComment;
