import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../firebase";

const FinalSubmit = () => {
  const location = useLocation();

  const handleSubmit = async () => {
    try {
      //   await setDoc(doc(db, "formData", "user1"), {
      //     input1: location.state?.input1,
      //     input2: location.state?.input2,
      //     input3: location.state?.input3,
      //   });
      //   console.log(input1)
      console.log("Data submitted successfully!");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <div>
      <h2>Final Step - Review & Submit</h2>
      <div>
        <p>Input 1: {location.state?.input1}</p>
        <p>Input 2: {location.state?.input2}</p>
        <p>Input 3: {location.state?.input3}</p>
      </div>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default FinalSubmit;
