import { useEffect, useRef, useState } from "react";

export const EditComment = ({
  commentId,
  existingComment,
  handleEdit,
  handleDeleteComment,
  setEditingPosts,
}) => {
  const [newComment, setNewComment] = useState(existingComment);
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "20px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newComment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEdit(commentId, newComment);
    setIsEditing(false);
  };

  return isEditing ? (
    <form onSubmit={handleSubmit} className="postlist_comment_text_form">
      <textarea
        ref={textareaRef}
        className="postlist_comment_text_textare"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        required
      />
      <div className="postlist_comment_text_select">
        <button type="submit" className="postlist_comment_text_updata">
          更新
        </button>
        <button
          className="postlist_comment_text_edit_calcel"
          type="button"
          onClick={() => setIsEditing(false)}
        >
          キャン
          <br />
          セル
        </button>
      </div>
    </form>
  ) : (
    <>
      <button
        onClick={() => setIsEditing(true)}
        className="postlist_comment_text_edit"
      >
        編集
      </button>
      <button
        type="button"
        onClick={() => setEditingPosts(false)}
        className="postlist_comment_text_cancel"
      >
        キャンセル
      </button>
      <button
        onClick={() => handleDeleteComment(commentId)}
        className="postlist_comment_text_delete"
      >
        削除
      </button>
    </>
  );
};
