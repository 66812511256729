import React, { useEffect, useState } from "react";
import "./TestDayo.scss"; // CSSをインポート

const TennisOffData = () => {
  const [confettiArray, setConfettiArray] = useState([]);

  useEffect(() => {
    const createConfetti = () => {
      const confettiCount = 100;
      const confettiElements = [];

      for (let i = 0; i < confettiCount; i++) {
        const fallDuration = Math.random() * 3 + 2; // 2秒〜5秒の間でランダム
        const fallDelay = Math.random() * 2; // 0秒〜2秒の間でランダム
        const isRotating = Math.random() < 0.9; // 50%の確率で回転させる

        const confettiStyle = {
          left: `${Math.random() * 100}%`,
          backgroundColor: `hsl(${Math.random() * 360}, 100%, 50%)`,
          "--fall-duration": `${fallDuration}s`, // 落下時間
          "--fall-delay": `${fallDelay}s`, // 遅延時間
        };

        // 回転させるかどうかを判定してクラスを適用
        const confettiClass = isRotating ? "confetti rotate" : "confetti";

        confettiElements.push(
          <div key={i} className={confettiClass} style={confettiStyle} />
        );
      }

      setConfettiArray(confettiElements);
    };

    createConfetti();
    const interval = setInterval(createConfetti, 5000);

    return () => clearInterval(interval);
  }, []);

  return <div className="confetti-container">{confettiArray}</div>;
};

export default TennisOffData;
